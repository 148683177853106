.contain {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.create {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 2.5rem 0 1rem 0;
}

.createFormContainer {
    width: 100%;
    max-width: 740px;
    margin-top: 1rem;
}